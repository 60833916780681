<template>
  <b-row class="mr-0 ml-0 w-100 d-flex">
    <b-card class="mb-2 pl-0 myproj-card knowledge-qa-card">
      <b-row class="d-flex knowledge-upperrow">
        <div :class="getTagClass" class="d-flex align-items-center library-card-tag library-card-tag-qa">
          <b-icon-clipboard-check v-if="data.status_qa == 'myApps.qaPairs.filters.status.verified'" class="knowledge-qa-card-label-icon"/>
          <b-icon-clipboard  v-if="data.status_qa == 'myApps.qaPairs.filters.status.needsAnswer'" class="knowledge-qa-card-label-icon"/>
          <b-icon-clipboard-minus v-if="data.status_qa == 'myApps.qaPairs.filters.status.needsReview'" class="knowledge-qa-card-label-icon"/>
          <span> {{ $t(data.status_qa) | titleUpperCase }}</span>
        </div>
        <span class="library-card-date openSans-400 fz-12 mx-2">{{ $t('myApps.qaPairs.cards.timeAgo') }}
          {{ data.last_modify | formatTime }}
        </span>
        <div class="knowledge-content-tags">
          <img class="cursor-pointer d-none" src="/assets/images/icon_bookmark.svg"/>
          <img @click="copyUrl" class="cursor-pointer library-document-action-icon" src="/assets/images/icon_share.svg"
                 v-b-popover.hover.bottom="copyMessage" />
        </div>
      </b-row>
        <div class="knowledge-content-container ta-left d-block knowledge-upperrow cursor-pointer" @click="goToQuestion">
          <h3 class="openSans-100 fz-20">{{ data.text }}</h3>
        </div>
        <div class="knowledge-content-container ta-left d-block">
          <h3 class="openSans-100 fz-11">Created {{ timeAgo }} <span v-if="data.created_by "> by {{ data.created_by }} </span><span v-if="data.modified_by">• Last edited by {{ data.modified_by }}</span></h3>
        </div>
    </b-card>
    <div class="knowledge-tags-container">
      <span class="openSans-400 fz-12 knowledge-tags" v-for="tag in data.tags_qa" :key="tag">{{tag}} </span>
    </div>
  </b-row>
</template>

<script>
import tools from "@/modules/common/utils/tools.js"
import moment from 'moment'


export default {
  name: 'QAListItemCard',
  props: {
    data: Object,
    currentPage: Number
  },
  data() {
    return {
      copyMessage: this.$t('knowledge.ask.popover.copyLink'),
      timeAgo: ''
    }
  },
  filters: {
    titleUpperCase: tools.titleUpperCase,
    formatTime: tools.formatTime
  },
  methods: {
    goToQuestion() {
      this.$router.push({
        name: "Question",
        params: { bot: this.$route.params.bot, id: this.data.id },
        query: { page: this.currentPage }
      })
    },
    copyUrl() {
      let inputId = this.data.id
      tools.copyToClipboard(`${window.location.origin}/${this.$store.getters.getInstance}/my-apps/knowledge/question/${inputId}`)
      this.copyMessage = this.$t('knowledge.ask.popover.copied')
    }
  },
  computed: {
    getTagClass(){
      switch(this.data.status_qa) {
        case 'myApps.qaPairs.filters.status.verified':
            return 'verified-tag';
        case 'myApps.qaPairs.filters.status.needsReview':
          return 'need-review-tag';
        case 'myApps.qaPairs.filters.status.needsAnswer':
          return 'need-answer-tag';
        default:
          return '';
      }
  }
  },
  created() {
    this.timeAgo = moment(this.data.created).fromNow()
  }
}
</script>

<style lang="scss" scoped>

  div.popover::v-deep {
    border: 1px solid var(--viridian);

    div.popover-body {
      color: var(--viridian) !important;
      padding: 0.5rem 0.75rem;
      min-height: 37px;
      text-align: center;
    }

  }

  .knowledge-qa-card-label-icon {
    margin-left: 13px;
    margin-right: 13px;
  }

  .knowledge-qa-card {
    margin-bottom: 12px !important;
    width: 995px;
    min-height: 103px;
    height: auto;
    padding-bottom: 5px;
  }

  .knowledge-upperrow {
    margin: 18px 0px 16px 0px !important;
  }

  .knowledge-content-tags {
    margin: 0px 29px 0px auto;
  }

  .knowledge-content-container {
    padding: 0px 50px 0px 40px
  }

    @media (max-width: 320px) {
    .library-card-tag.library-card-tag{
      margin: 0rem calc(9rem - 20px) 1rem 0rem;
    }
  }


</style>