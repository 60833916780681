import Repository from '@/modules/common/utils/Repository.js'
import store from "@/store"

export default {
  get(extraFilters = {}) {
    extraFilters['bot_name'] = store.getters.getInstance
    return Repository.dbService.get('/questions/search', {
      params: extraFilters
    })
  },
  count(extraFilters = {}) {
    extraFilters['bot_name'] = store.getters.getInstance
    return Repository.dbService.get('/questions/count', {
      params: extraFilters
    })
  },
  save(qaPairData) {
    qaPairData['bot_id'] = store.getters.getInstance
    return Repository.dbService.post('/questions/save', qaPairData)
  },
  updateResponse(qaPairData) {
    qaPairData['bot_id'] = store.getters.getInstance
    return Repository.dbService.post('/questions/update_response', qaPairData, {
      params: {
        'bot_name': store.getters.getInstance
      }
    })
  },
  getFavoriteQuestions(botId) {
    return Repository.dbService.get('questions/favorites', {
      params: {
        'bot_name': botId
      }
    })
  },
  removeResponse(inputId, responseId) {
    return Repository.dbService.delete('/questions/remove_response', {
      params: {
        input_id: inputId,
        response_id: responseId,
        bot_name: store.getters.getInstance
      }
    })
  },
  removeQA(inputId) {
    return Repository.dbService.delete('/questions/remove_question', {
      params: {
        input: inputId,
        bot_name: store.getters.getInstance
      }
    })
  },
  modifyText(inputId, text) {
    let data = {
      input_id: inputId,
      new_text: text
    }
    return Repository.dbService.post('/questions/modify_text', data, {
      params: {
        bot_name: store.getters.getInstance
      }
    })
  },
  getQuestion(botName, qaId) {
    return Repository.dbService.get('/questions/get', {
      params: { 'bot_name': botName, 'input_id': qaId }
    })
  },
  setFavorite(inputId, value) {
    return Repository.dbService.post('/questions/set_favorite', {
      input_id: inputId,
      value: value
    })
  },
  setVerified(questionData) {
    return Repository.dbService.post('questions/verify', questionData, {
      params: {
        bot_name: store.getters.getInstance
      }})
  }
}