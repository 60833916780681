<template>
  <div class="container-base section-container-padding bg-white">
    <div class="myproj-container settings-my-instances-tabs knowledge">
      <knowledge-tabs tab-name="questions"/>
      <b-row>
        <b-col class="margin-cards ask-question-result-margin">
          <b-row class="mr-0 knowledge-qa-header-row">
            <span class="fz-25 openSans-100 mobile-margin">{{ $t('myApps.qaPairs.header.title') }}</span>
            <span class="fz-14 openSans-100 knowledge-qa-totalizer" v-if="finishLoadData()">
              ({{ $tc('myApps.qaPairs.header.entries', totalItems, { count: totalItems }) }})
            </span>
            <span v-if="canCreateQA()" class="fz-14 openSans-100 knowledge-qa-addnewSpan cursor-pointer ml-2" @click="addNewQAPair">
              <img src="/assets/images/icon_add.svg" class="knowledge-qa-addnew">{{ $t('myApps.qaPairs.header.addNew') }}
            </span>
          </b-row>

          <b-row class="margin-rows knowledge-qa-filters-row">
            <b-input-group class="qa-input-group">
              <b-form-input class="knowledge-qa-input-search" type="search"
                            v-model="filters.text_filter"
                            @keyup.enter="searchQA"
                            :placeholder="$t('myApps.qaPairs.filters.search')">
              </b-form-input>
              <b-input-group-append @click="searchQA">
                <img class="cursor-pointer knowledge-qa-input-search-icon" :src="'/assets/images/icon_knowledge_qa_search.svg'"/>
              </b-input-group-append>
            </b-input-group>

            <b-form-select class="document-select w-40 knowledge-qa-filter-select" v-model="filters.status" @change="searchQA">
              <b-form-select-option v-for="option in options.status" v-bind:key="option.value" :value="option.value">{{ $t(option.text) }}</b-form-select-option>
            </b-form-select>

            <b-form-select class="document-select w-40 knowledge-qa-filter-select" v-model="filters.tag" @change="searchQA" :options="options.tags">
              <template #first>
                <b-form-select-option :value="null">{{ $t('myApps.qaPairs.filters.tag.any') }}</b-form-select-option>
              </template>
            </b-form-select>

            <b-form-select class="document-select w-40 knowledge-qa-filter-select" v-model="filters.since" @change="searchQA">
              <b-form-select-option v-for="option in options.since" v-bind:key="option.value" :value="option.value">{{ $t(option.text) }}</b-form-select-option>
            </b-form-select>

            <div class="knowledge-qa-tags-title-container"><span class="openSans-400 fz-12">Tags:</span></div>
          </b-row>

          <div v-if="finishLoadData()">
            <b-row class="margin-rows mr-0">
              <q-a-list-item-card v-for="qaPair in qaPairs" :data="qaPair" :current-page="currentPage" :key="qaPair.id"></q-a-list-item-card>
            </b-row>
            <b-row>
              <b-col>
                <b-pagination
                  v-model="currentPage"
                  @change="changePage"
                  align="center"
                  :total-rows="totalItems"
                  :per-page="pageSize"
                  first-number
                  last-number>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
          <div v-else>
            <b-row>
              <b-col class="wait-response-spinner my-3">
                <b-spinner class="m-0" variant="success" label="Spinning"></b-spinner>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import QAService from "@/modules/knowledge/services/QAService.js"
import { PERM_MODIFY_QA } from '@/modules/security/services/SecurityService.js'

import KnowledgeTabs from "@/modules/knowledge/components/KnowledgeTabs.vue"
import QAListItemCard from "@/modules/knowledge/components/QAListItemCard.vue"

export default {

  name: 'QAPairsList',

  components: {
    KnowledgeTabs, QAListItemCard
  },
  data () {
    return {
      qaPairs: [],
      totalItems: 0,
      filters: {
        text_filter: null,
        status: null,
        tag: null,
        since: '9999'
      },
      options: {
        status: [
          { value: null, text: 'myApps.qaPairs.filters.status.any' },
          { value: '0', text: 'myApps.qaPairs.filters.status.needsAnswer' },
          { value: '1', text: 'myApps.qaPairs.filters.status.needsReview' },
          { value: '2', text: 'myApps.qaPairs.filters.status.verified' }
        ],
        tags: [],
        since: [
          { value: '9999', text: 'myApps.qaPairs.filters.since.anyTime' },
          { value: '1', text: 'myApps.qaPairs.filters.since.today' },
          { value: '7', text: 'myApps.qaPairs.filters.since.pastWeek' },
          { value: '30', text: 'myApps.qaPairs.filters.since.pastMonth' },
          { value: '365', text: 'myApps.qaPairs.filters.since.pastYear' }
        ]
      },
      pageSize: 5,
      currentPage: 1,
      finishList: false,
      finishCount: false
    }
  },
  methods: {
    searchQA() {
      let filters = this.buildFilter()
      this.finishList = false
      QAService.get(filters).then(response => {
        let data = response.data
        this.options.tags = data.filter_tags
        this.qaPairs = data.queue
        this.finishList = true
      })
      this.updateTotal()
    },
    updateTotal() {
      let filters = this.buildFilter()
      this.finishCount = false
      QAService.count(filters).then(response => {
        this.totalItems = response.data.total_queue
        this.finishCount = true
      })
    },
    buildFilter() {
      let filters = this.filters
      if (this.$route.query.page) {
        this.currentPage = parseInt(this.$route.query.page)
      }
      filters.start = (this.currentPage - 1) * this.pageSize
      filters.quantity = this.pageSize
      return filters
    },
    changePage(value) {
      this.currentPage = value
      this.$router.push({ name: "QAPairs", params: { bot: this.$route.params.bot }, query: { page: this.currentPage } })
      this.searchQA()
    },
    finishLoadData() {
      return  this.finishList && this.finishCount
    },
    addNewQAPair() {
      this.$router.push('question/create')
    },
    canCreateQA() {
      return this.botPermissions.indexOf(PERM_MODIFY_QA) > -1
    }
  },
  created() {
    if (this.$store.getters.hasSelectedInstance) {
      this.searchQA()
    }
  },
  computed: {
    currentInstance() {
      return this.$store.getters.getInstance
    },
    botPermissions() {
      return this.$store.getters.getInstancePermissions
    }
  },
  watch: {
    currentInstance() {
      this.searchQA()
    }
  }
}
</script>

<style lang="scss" scoped>

  .ask-question-result-margin {
    padding: 29px 25px;
  }

  .knowledge-qa-totalizer {
    margin: 0px 25px 4px 15px;
    display: inline-flex;
    align-items: flex-end;
  }

  .knowledge-qa-addnew {
    margin-right: 10px;
  }

  .knowledge-qa-filters-row {
    margin-bottom: 12px;
  }

  .qa-input-group {
    width: auto;
  }

  .knowledge-qa-input-search-icon {
    border: 1px solid #ced4da;
    border-left:transparent;
    padding: 10px;
    border-radius: 0px 0.25rem 0.25rem 0px;
    margin-right: 12px;
  }

  .knowledge-qa-input-search {
    max-width: 364px;
    min-width: 318px !important;
    height: 46px;
    border-right: transparent;
  }

  .document-select {
    width: 40%;
    color: #28a745;
    border-color: #28a745;
  }

  .knowledge-qa-filter-select {
    max-width: 198px;
    height: 46px;
    margin-right: 12px;
  }

  .knowledge-qa-tags-title-container {
    display: inline-flex;
    margin-top: 25px;
    vertical-align: text-top;
    margin-left: 25px;
  }

  ::v-deep {

  @media (min-width: 320px) and (max-width: 1024px) {

      .myproj-container ul.nav.nav-tabs li a.nav-link {
        width: 90px;
        display: flex;
        justify-content: center;
        margin-right: unset;
      }

      .myproj-container ul.nav.nav-tabs li.nav-item:first-of-type, .knowledge-qa-totalizer {
        margin-left: unset;
      }

      .knowledge-qa-filters-row {
        margin-top: 20px !important;
      }

      .knowledge-qa-addnewSpan {
        margin-left: auto !important;
      }

      .qa-input-group {
        width: 100%;
      }
      
      .knowledge-qa-input-search {
        max-width: unset;
        min-width: unset !important;
      }

      .mobile-preloader {
        justify-content: flex-end;
      }

      .wait-response-spinner {
        margin-left: 0px;
      }
      
      .knowledge-qa-filter-select {
        width: 100%;
        margin: 10px auto;
        max-width: unset;
      }

      .knowledge-qa-input-search-icon {
        margin-right: unset;
      }

      .knowledge-qa-tags-title-container {
        display: none;
      }

      .knowledge-content-tags {
        padding-top: 4px;
        margin-right: 16px;
      }

      .mobile-margin {
        margin-bottom: 10px;
      }

    }
  } 


</style>